@use '../config/' as *;
.continental-federations-listing {
    .waf-majorevents {
        @extend %p-0;
        @extend %page-head-blue-bg;
        .waf-head{
            margin-block: 0;
        }
        &::before{
            content: unset;
        }
    }
    @each $federation, $federation-img in $federation-bg{
        &.#{$federation}{
            .waf-majorevents{
                .waf-head{
                    background: url("/static-assets/images/cssimages/world-ranking/#{$federation-img}.png?v=#{$image-version}") bottom center/cover no-repeat;
                }
            }
        }
    }
}
.waf-majorevents {
    padding-top: var(--header-height);
    @extend %common-input-swiper-btn;
    .layout-wrapper{
        max-width: var(--lg-container-max-width);
    }
    &::before {
        content: "";
        height: calc(var(--header-height) + 130rem);
        pointer-events: none;
        rotate: 180deg;
        border-radius: 1.6rem 1.6rem 0 0;
        @include position-combo(inset);
        @extend %bg-primary-50;
        @extend %zindex-pattern;
    }
    .waf {
        &-head {
            @extend %m-y-14-10;
        }
    }
    .title,
    .desc {
        @extend %neutral-0;
    }
    .title {
        @extend %title-40;
        @extend %mb-2;
    }
    .desc {
        @extend %title-60;
    }
    .masthead-section,
    .content-section {
        @extend %border-radius-m;
        @extend %mb-8;
        @extend %bg-neutral-0;
        @extend %p-6-4;
        .title {
            @extend %neutral-100;
            @extend %title-50;
        }
    }
    .masthead-section {
        .card {
            &-list {
                @extend %pt-4;
                @include card-count(1, var(--space-2), wrap);
            }
            &-title {
                @extend %d-none;
            }
            &-item {
                border: .1rem solid var(--neutral-20);
                background: linear-gradient(223deg, var(--informative-10) 0%, hsl(var(--hsl-informative-10) / 0) 75.04%), var(--neutral-5);
                isolation: isolate;
                @extend %p-6;
                @extend %border-radius-s;
                @extend %flex-c-c;
                @extend %relative;
                &:hover {
                    border-color: var(--dark-focus-0);
                }
            }
            &-thumbnail {
                pointer-events: none;
                @include position-combo(inset);
                @extend %d-block;
                @extend %zindex-pattern;
                img {
                    object-fit: cover;
                    object-position: center;
                    @extend %w-100;
                    @extend %h-100;
                    @extend %border-radius-s;
                }
            }
            &-logo {
                .image {
                    width: auto;
                    height: 4.8rem;
                }
            }
        }
        .btn-link {
            @include position-combo(inset);
            @extend %font-0;
        }
    }
    label {
        @extend %d-block;
        @extend %w-100;
        @extend %title-60;
    }
    .btn-close {
        transform: translateY(1.8rem);
    }
    .site-search-form {
        @extend %mt-10;
    }
    .search-results {
        &-content {
            border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
            box-shadow: 0 .2rem .8rem 0 var(--neutral-10);
            max-height: 18.4rem;
            @extend %y-auto;
            @extend %d-none;
            &.active {
                @extend %flex;
            }
            .text {
                @extend %text-mb;
                @extend %pr-5;
            }
            .meta-country {
                @extend %gap-5;
                @extend %relative;
                &::after {
                    content: "";
                    width: 0.4rem;
                    height: .4rem;
                    aspect-ratio: 1/1;
                    position: absolute;
                    left: var(--space-8);
                    @extend %circle-radius;
                    @extend %bg-neutral-20;
                }
            }
        }
        &-list {
            @extend %w-100;
        }
        &-item {
            @include border(1, neutral-20, 10, bottom);
            @extend %p-4;
        }
    }
    .search-item-link {
        @extend %relative;
        &::after {
            transform: rotate(45deg);
            @include icon(arrow-up, 24);
            @include position-combo(y-center, 0);
        }
    }
    .search-input{
        @extend %mt-4;
    }
    .content-section {
        .card {
            &-list {
                @extend %mt-10;
            }
            &-item {
                @include border(1, neutral-20, 10, bottom);
                @extend %flex-sb-n;
                @extend %gap-6;
                @extend %py-4;
            }
            &-content {
                max-width: 68%;
                @extend %flex-column;
                @extend %gap-2;
            }
            &-title {
                @extend %body-m;
            }
        }
    }
    .card {
        &-tag {
            .text {
                width: max-content;
                @extend %tag-outline;
                &:hover{
                    @extend %bg-transparent;
                }
                &:empty{
                    @extend %d-none;
                }
            }
        }
        &-meta {
            height: max-content;
            min-width: 6.4rem;
        }
    }
    .meta {
        @extend %text-m;
        @extend %uppercase;
        &-logo {
            width: 2.4rem;
            aspect-ratio: 1/1;
            object-fit: contain;
        }
        &-country {
            min-width: 6.4rem;
            @extend %flex-n-c;
            @extend %gap-2;
        }
    }
    .btn-loadmore {
        @extend %mt-10;
        @extend %gap-2;
        @extend %w-100;
        @extend %text-center;
        @extend %mx-auto;
        .btn-text {
            @extend %btn-default;
        }
    }
}
@include mq(col-tablet) {
    .continental-federations-listing {
        .waf-majorevents {
            .waf-head{
                margin-inline: 0;
            }
           .site-search-form{
            margin-top: 0;
           }
           .search-input{
                margin-top: var(--space-10);
            }
            .btn-close{
                transform: translateY(2.8rem);
            }
        }
    }
    .waf-majorevents {
        .waf-head {
            margin-block: 10.5rem 6.4rem;
            margin-inline: var(--space-10);
        }
        .card {
            &-item {
                padding-block: 4.8rem;
            }
        }
        .content-section {
            .card {
                &-content {
                    flex-direction: row;
                    align-items: center;
                }
            }
        }
        .search-results-content {
            max-height: 23.4rem;
            @include custom-scroll();
            .meta-country {
                ::after {
                    left: var(--space-8);
                }
            }
        }
        .masthead-section {
            .card{
                &-list{
                    gap: var(--space-4);
                    & > *{
                        width: calc(100% / 2 - (var(--space-4) / 2));
                    }
                }
                &-item{
                    padding: var(--space-12) var(--space-6);
                }
            }
        }
    }
}
@include mq(col-lg) {
    .continental-federations-listing {
        .waf-majorevents {
            .btn-close{
                transform: translateY(3.8rem);
            }
        }
    }
    .waf-majorevents {
        .title {
            font-size: 5.6rem;
        }
        .desc {
            font-size: 2rem;
            max-width: 52rem;
        }
        label {
            font-size: 2.4rem;
        }
        .masthead-section {
            padding: var(--space-20) var(--space-10);
            .card{
                &-list{
                    & > *{
                        width: calc(100% / 3 - (4 * var(--space-4) / 5));
                    }
                }
            }
        }
        .content-section {
            padding: var(--space-20);
            .card {
                &-title {
                    font-size: 1.6rem;
                }
            }
        }
        .masthead-section,
        .content-section {
            .title {
                font-size: 4rem;
            }
        }
        .search-results-content {
            .text {
                font-size: 1.6rem;
            }
        }
        .btn-close {
            transform: translateY(2.8rem);
        }
    }
}
@include mq(col-desktop) {
    .waf-majorevents {
        .masthead-section {
            .card-list {
                & > *{
                    width: calc(100% / 5 - (4 * var(--space-4) / 5));
                }
            }
        }
    }
}