@use './config/' as *;
@forward './main.scss';
@forward './majorevents/federation-list';
.load-bg-pattern {
    .waf-majorevents {
        &::before {
            @include bgImg('cssimages/eventlisting-bg.png', center, cover);
        }
    }
}
@include mq(col-tablet) {
    .load-bg-pattern {
        .waf-majorevents {
            &::before {
                rotate: unset;
                transform: rotateX(-180deg);
                @include bgImg('cssimages/pattern/head-to-head-bg-web.png');
            }
        }
    }
}